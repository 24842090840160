<template>
  <v-app style="background-color: rgba(0,0,0,0)">
    <v-main style="background-color: rgba(0,0,0,0)">
      <div>
        <Forms/>
      </div>
    </v-main>
  </v-app>

</template>

<script>
import Forms from './components/Forms';

export default {
  name: 'App',

  components: {
    Forms,
  },
};
</script>

<style>
@import '~@/assets/static/static.css';
/*
#app {
  background: url('~@/assets/256_simpl.png') !important;
  background-size: 256px 256px !important;
  background-repeat: ;
  background-origin: content-box;
  overflow:hidden;
  background-size: cover;

}  */





</style>
