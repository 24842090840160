<template>
  <v-container fluid class="mx-3 my-3">
    <v-form v-model="formValid" ref="form">
        <div class="container">
            <v-row>

                <v-col md="3" class="border-col" :style="{backgroundColor: agencyInfosBackgroundColor, border: '1px solid black', padding: '10px',
                        borderRadius: '5px',boxShadow: '10px 5px 5px #a7a7a7' }">
                    <div style="text-align: center; margin: 5px">
                        <div>
                            <v-row align="center" justify="center">
                                <a :href="agencyWebsite">
                                    <v-img
                                        max-height="150"
                                        max-width="350"
                                        :src="agencyLogo"
                                    ></v-img>
                                </a>

                            </v-row>
                        </div>

                        <v-row align="center" justify="center">
                            <div class="mt-10">
                                <span class="text-h6" :style="{color: agencyTextTitleColor}">{{ agencyTextTitle }}</span>
                            </div>
                        </v-row>

                        <v-row align="center" justify="center">
                            <v-col>
                                <div class="mt-2" :style="{color: agencyTextContentColor}">
                                    <p>
                                        <span v-html="agencyTextContent"></span>
                                    </p>
                                </div>
                            </v-col>
                        </v-row>
                    </div>
                </v-col>

                <v-col :md="colClient" offset-md="2" class="border-col" style="margin-left: 3rem; margin-right: 3rem">
                    <div class="section-infos">
                        <v-row align="center" justify="center" class="head-section">
                            <v-col :style="{backgroundColor: agencyInfosBackgroundColor, 'borderRadius' : '5px'}">
                                <div class="big-section">
                                    <span class="text-h5 font-weight-bold" :style="{color : agencyTextContentColor}" >{{ $t('sectionClient.title') }}</span>
                                </div>
                            </v-col>

                        </v-row>

                        <!-- Contact information  -->
                        <v-row align="center" justify="center">
                            <v-col>
                                <v-card>
                                    <v-card-title class="vcard-title"><span class="text-h6">{{ $t('sectionClient.contactInformationSection.title') }}</span></v-card-title>
                                    <v-card-text>
                                        <v-row dense class="mt-2">

                                            <v-col md="6">
                                                <v-text-field dense outlined v-model="form.firstName" :rules="nameRules"
                                                              :label="$t('sectionClient.contactInformationSection.firstNameInputPlaceholder')">
                                                </v-text-field>
                                            </v-col>

                                            <v-col md="6">
                                                <v-text-field dense outlined v-model="form.lastName" :rules="nameRules"
                                                              :label="$t('sectionClient.contactInformationSection.lastNameInputPlaceholder')">
                                                </v-text-field>
                                            </v-col>

                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>

                        <!-- Adresse -->
                        <v-row align="center" justify="center">
                            <v-col>
                                <v-card>
                                    <v-card-title class="vcard-title"><span class="text-h6">{{ $t("sectionClient.addressSection.title") }}</span></v-card-title>
                                    <v-card-text>
                                        <v-row dense>
                                            <v-col md="6">
                                                <v-select dense outlined v-model="form.province" :rules="provinceRules" :items="provinces" item-text="label" item-value="value"
                                                          :label="$t('sectionClient.addressSection.provinceSelectLabel')">

                                                </v-select>
                                            </v-col>

                                            <v-col md="6">
                                                <v-text-field @keyup.native="uppercaseField()"
                                                              dense outlined v-model="form.postalCode" :rules="postalCodeRules"
                                                              :label="`${ $t('sectionClient.addressSection.postalCodeInputPlaceholder') } | Format : HOH OHO`">
                                                </v-text-field>
                                            </v-col>
                                        </v-row>

                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>

                        <!-- How to reach you -->
                        <v-row align="center" justify="center">
                            <v-col>
                                <v-card>
                                    <v-card-title class="vcard-title"><span class="text-h6">{{ $t('sectionClient.reachSection.title') }}</span></v-card-title>
                                    <v-card-text>
                                        <v-row dense>
                                            <v-col md="6">
                                                <v-text-field dense outlined v-model="form.email" :rules="emailRules"
                                                              :label="$t('sectionClient.reachSection.emailPlaceholder')"></v-text-field>
                                            </v-col>

                                            <v-col md="6">
                                                <v-text-field dense outlined v-model="form.mobilePhone" :rules="mobilePhoneRules"
                                                              :label="`${ $t('sectionClient.reachSection.mobilePhonePlaceholder') } | Format : (123) 456-7890`">
                                                </v-text-field>
                                            </v-col>
                                        </v-row>


                                        <v-row dense>
                                            <v-col md="6">
                                                <v-select dense outlined v-model="form.language" :rules="languageRules" :items="languages"
                                                          :label="$t('sectionClient.reachSection.languageSelectLabel')"
                                                          item-text="label" item-value="value"></v-select>
                                            </v-col>

                                            <v-col md="6" v-if="!isB2C">
                                                <div class="d-flex flex-row justify-center">
                                                    <div><span style="font-weight: bold;font-size: 1.1rem;">
                                                        {{ $t('sectionClient.reachSection.sendAutomaticEmailLabel')}} :</span></div>
                                                    <div>
                                                        <v-checkbox
                                                            style="margin-top: -5px;margin-left: 15px;"
                                                            v-model="form.sendAutomaticEmail"
                                                        >
                                                        </v-checkbox>
                                                    </div>
                                                </div>
                                            </v-col>
                                        </v-row>


                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>

                        <!-- Policy information -->
                        <v-row align="center" justify="center">
                            <v-col>
                                <v-card>
                                    <v-card-title class="vcard-title"><span class="text-h6"> {{ $t("sectionClient.policiyInformationSection.title") }}</span></v-card-title>
                                    <v-card-text>
                                        <div class="container--fluid mt-4">
                                            <div>
                                                <div class="d-flex pt-2 pl-2 pr-2">
                                                    <div class="mr-4">
                                                        <v-select
                                                            :items="policiyTypes"
                                                            item-text="label"
                                                            item-value="value"
                                                            :label="$t('sectionClient.policiyInformationSection.policiesSelectLabel')"
                                                            v-model="form.policyType"
                                                            multiple
                                                            :rules="policyTypeRules"
                                                        ></v-select>
                                                    </div>
                                                    <div class="ml-auto">
                                                        <v-select
                                                            @change="transactionDateValue"
                                                            :rules="transactionDateRules"
                                                            v-model="transactionDate"
                                                            :items="dateItems"
                                                            item-text="text"
                                                            item-value="value"
                                                            filled
                                                            :label="$t('sectionClient.policiyInformationSection.renewalDateSelectLabel')"
                                                        ></v-select>
                                                    </div>
                                                </div>

                                                <div v-if="policyTypeLifeExists">
                                                    <v-row>
                                                        <v-col cols="12">
                                                            <v-textarea
                                                                outlined
                                                                name="input-7-4"
                                                                :label="$t('sectionClient.policiyInformationSection.description')"
                                                                v-model="form.description"
                                                            ></v-textarea>
                                                        </v-col>
                                                    </v-row>
                                                </div>

                                                <v-divider></v-divider>
                                            </div>

                                        </div>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>

                    </div>

                    <v-row align="center" justify="center" class="mt-5">
                        <v-btn v-if="isB2C" depressed :color="btnSubmitBackgroundColor" @click="validate">
                                <span :style="{color: btnSubmitForeColor}">
                                    {{ $t("sectionMortageBroker.sectionMortageAgent.btnSendReferral") }}
                                </span>
                        </v-btn>
                    </v-row>

                </v-col>

                <!-- Mortgage Broker Section -->
                <v-col md="3" v-if="!isB2C" class="border-col">
                    <div class="section-infos">
                        <v-row class="head-section">
                            <v-col :style="{backgroundColor: agencyInfosBackgroundColor, 'borderRadius' : '5px'}">
                                <div class="big-section">
                                    <span class="text-h5 font-weight-bold" :style="{color : agencyTextContentColor}" >
                                        {{ sectionAgentTitle }}
                                    </span>
                                </div>
                            </v-col>

                        </v-row>

                        <v-row align="center" justify="center">
                            <v-col>
                                <v-card>
                                    <v-card-title class="vcard-title">
                                      <span class="text-h6">
                                         <template v-if="agencyRefereeTypeIsRealEstateAgent">
                                          {{ $t("sectionMortageBroker.sectionMortageAgent.title.realEstateAgent") }}
                                        </template>
                                        <template v-else>
                                          {{ $t("sectionMortageBroker.sectionMortageAgent.title.mortgageBroker") }}
                                        </template>
                                        </span></v-card-title>
                                    <v-card-text>

                                        <v-row dense>

                                            <v-col>
                                                <v-autocomplete
                                                    :search-input.sync="searchMortgageBrokers"
                                                    :loading="mortageBrokersListIsLoading"
                                                    clearable
                                                    hide-no-data
                                                    v-model="selectedAgent"
                                                    :items="mortgageBrokers"
                                                    item-text="mbrk_name"
                                                    item-value="id"
                                                    dense
                                                    filled
                                                    :label="agentListLabel"
                                                    :placeholder="$t('sectionMortageBroker.sectionMortageAgent.selectMortgageAgentPlaceholder')"
                                                    @change="checkSelectedAgent"
                                                    @click:clear="clearMortgageAgentInfos"
                                                ></v-autocomplete>
                                            </v-col>

                                        </v-row>

                                        <v-row>
                                            <v-col>
                                                <p>
                                                    {{ $t("sectionMortageBroker.sectionMortageAgent.labelNoAgentFound") }}
                                                </p>
                                            </v-col>
                                        </v-row>

                                        <v-row dense >
                                            <v-col>
                                                <v-text-field outlined dense :readonly="mortgageAgentLockInfos" v-model="form.mortgageAgent.name" :rules="mortgageAgentName"
                                                              :label="$t('sectionMortageBroker.sectionMortageAgent.agentNameLabel')">
                                                </v-text-field>
                                            </v-col>
                                        </v-row>

                                        <v-row dense>
                                            <v-col>
                                                <v-text-field outlined dense :readonly="mortgageAgentLockInfos" v-model="form.mortgageAgent.email"
                                                              :label="$t('sectionMortageBroker.sectionMortageAgent.agentEmailLabel')">
                                                </v-text-field>
                                            </v-col>
                                        </v-row>

                                        <v-row dense>
                                            <v-col>
                                                <v-text-field outlined dense :readonly="mortgageAgentLockInfos" v-model="form.mortgageAgent.phone"
                                                              :label="$t('sectionMortageBroker.sectionMortageAgent.agentPhoneNumber')">
                                                </v-text-field>
                                            </v-col>
                                        </v-row>

                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>

                        <v-row align="center" justify="center">
                            <v-btn v-if="showMortgageBrokerPdfBtn" depressed color="error" @click="showMortgageBrokerPdf">
                                <v-icon class="mr-2">mdi-file-pdf-box-outline</v-icon>
                                {{ $t("sectionMortageBroker.sectionMortageAgent.btnMarketingMaterial") }}
                            </v-btn>
                        </v-row>

                        <v-row align="center" justify="center" class="mt-5">
                            <v-btn depressed :color="btnSubmitBackgroundColor" @click="validate">
                                <span :style="{color: btnSubmitForeColor}">
                                    {{ $t("sectionMortageBroker.sectionMortageAgent.btnSendReferral") }}
                                </span>
                            </v-btn>
                        </v-row>
                    </div>
                </v-col>

            </v-row>
        </div>

    </v-form>


      <v-dialog
          v-model="dialog"
          hide-overlay
          persistent
          width="300"
      >
          <v-card
              color="primary"
              dark
          >
              <v-card-text>
                  {{ $t("pleaseStandByMessage") }}
                  <v-progress-linear
                      indeterminate
                      color="white"
                      class="mb-0"
                  ></v-progress-linear>
              </v-card-text>
          </v-card>
      </v-dialog>

      <v-dialog v-model="dialogSuccess" width="500">
          <v-card>
              <v-card-text>
                  <div class="pt-4">
                      <v-icon color="green" class="mr-4">mdi-thumb-up</v-icon> <span class="text-h6"> {{ $t("formSubmittedSuccessfully")}} </span>
                  </div>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                      color="sucess"
                      text
                      @click="dialogSuccess = false"
                  >
                      {{ $t("close") }}
                  </v-btn>
              </v-card-actions>
          </v-card>
      </v-dialog>

      <v-dialog v-model="dialogError" width="500">
        <v-card>
          <v-card-text>
              <div class="pt-4">
                <v-icon color="red" class="mr-4">mdi-close-thick</v-icon>
                <span class="text-h6">
                    {{ $t("formError")}}
                </span>
              </div>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  color="sucess"
                  text
                  @click="dialogError = false"
              >
                  Close
              </v-btn>
          </v-card-actions>
      </v-card>
      </v-dialog>

       <v-dialog v-model="initialDialog" width="500">
           <v-card>
               <v-card-text>
                   <v-row align="center" justify="center">
                       <span class="text-h6 mt-3">
<!--                           {{ $t("pleaseWaitLoadingData") }}-->
                       </span>
                   </v-row>
                   <v-row align="center" justify="center">
                        <v-progress-circular
                            :size="70"
                            :width="7"
                            color="blue"
                            indeterminate
                            class="mt-3"
                        ></v-progress-circular>
                   </v-row>
               </v-card-text>
           </v-card>
       </v-dialog>


  </v-container>
</template>

<script>
import axios from '@/axios';

export default {
    name: 'Forms',

    computed : {

        isB2C(){
            return this.agency ? this.agency.cf_1594 === "B2C" : false;
        },

        isNotB2C(){
            return this.agency ? this.agency.cf_1594 !== "B2C" : false;
        },

        sectionAgentTitle(){
          let retval = "";
          if(this.agency){
            if(this.agencyRefereeTypeIsRealEstateAgent){
              retval = this.$i18n.t('sectionMortageBroker.title.realEstateAgent');
            } else {
              retval = this.$i18n.t('sectionMortageBroker.title.mortgageBroker');
            }
          } else {
            retval = "";
          }
          return retval;
        },
        agencyRefereeTypeIsRealEstateAgent(){
          if(this.agency){
            if(this.agency.cf_1606.toLowerCase() === "real estate agent"){
              return true;
            } else {
              return false;
            }
          } else {
            return false;
          }
        },

      agentListLabel(){
          let retval = "";

          if(this.agency){
            if(this.agencyRefereeTypeIsRealEstateAgent){
              retval = this.$i18n.t('sectionMortageBroker.sectionMortageAgent.selectRealEstateAgentLabel');
            } else {
              retval = this.$i18n.t('sectionMortageBroker.sectionMortageAgent.selectMortgageAgentLabel');
            }
          } else {
            retval = "";
          }

        return retval;
      },

        colClient(){
            if(this.agency){
               if(this.agency.cf_1594 === "B2C"){
                   return "8";
               } else {
                   return "5";
               }
            } else {
                return "5";
            }
        },

        policyTypeLifeExists(){
            return this.form.policyType ? this.form.policyType.includes("Life") : false ;
        },

        agencyWebsite(){
          return this.agency ? this.agency.agen_website : "";
        },

        btnSubmitBackgroundColor(){
            return this.agency ? this.agency.agen_btn_bckg_color : ""
        },

        btnSubmitForeColor(){
            return this.agency ? this.agency.agen_btn_txt_color : ""
        },
        agencyInfosBackgroundColor(){
            return this.agency !== null ? this.agency.agen_background_color : "";
        },

          agencyLogo(){
              if(this.agency){
                  // if(this.agency.agen_logo_remote_path){
                  //     return this.agency.agen_logo_remote_path;
                  // }

                  if(this.agency.agen_logo64){
                      return this.agency.agen_logo64;
                  }

                  return "";
              } else {
                  return "";
              }
          },
        agencyTextTitle(){
            return this.agency ? this.agency.agen_text_title : "";
        },
        agencyTextTitleColor(){
            return this.agency ? this.agency.agen_title_color : "";
        },
        agencyTextContent(){
            let res = "";

            if(this.agency){
                res = this.agency.agen_text_content;
                res = res.replace("[BDM_NAME]", this.agency.agen_bdm_name);
                res = res.replace("[BDM_PHONE]", this.agency.agen_bdm_phone);
                res = res.replace("<a href=\"mailto:%20\">", "");
                res = res.replace("</a>", "");
                res = res.replace("[BDM_EMAIL]", `<a href="mailto:${this.agency.agen_bdm_email}">${this.agency.agen_bdm_email}</a>`);
                return res;
            } else {
                return "";
            }
        },

        agencyTextContentColor(){
            return this.agency ? this.agency.agen_text_color : "";
        },

        showMortgageBrokerPdfBtn(){
            return !!this.form.mortgageAgent.phone && !!this.form.mortgageAgent.email && !!this.form.mortgageAgent.name && !!this.agency.cf_1351; //champs cf_1351 == template material
        },

        transactionDateLabel(){
          if(this.form.policyType){
              if(this.form.policyType === "Auto"){
                return "Renewal date";
              } else if (this.form.policyType === "Habitation") {
                  return "Mortgage closing date";
              } else {
                  return "Transaction Date";
              }
          }  else {
              return "";
          }
        },

        dateItems(){
            return [
                {
                    text: this.$i18n.t('sectionClient.policiyInformationSection.lessThen7Days'),
                    value: 7
                },
                {
                    text: this.$i18n.t('sectionClient.policiyInformationSection.between8And14Days'),
                    value: 14
                },
                {
                    text: this.$i18n.t('sectionClient.policiyInformationSection.between15And30Days'),
                    value: 30
                },
                {
                    text: this.$i18n.t('sectionClient.policiyInformationSection.between31And60Days'),
                    value: 60
                },
                {
                    text: this.$i18n.t('sectionClient.policiyInformationSection.between60And90Days'),
                    value: 90
                },
                {
                    text: this.$i18n.t('sectionClient.policiyInformationSection.greaterThan91Days'),
                    value: 91
                }
            ];
        },

        streetAddressRulesMessage(){
            return this.$i18n.t('validationRules.streetAddressRequired');
        },

        cityRulesMessage(){
            return this.$i18n.t('validationRules.cityRequired');
        },

        provinceRulesMessage(){
            return this.$i18n.t('validationRules.provinceRequired');
        },

        mobilePhoneRulesMessage(){
            return this.$i18n.t('validationRules.mobilePhoneRequired');
        },

        availabilitiesRulesMessage(){
            return this.$i18n.t('validationRules.availabilitiesRequired');
        },

        languageRulesMessage(){
            return this.$i18n.t('validationRules.languageRequired');
        },

        policyTypeRulesMessage(){
            return this.$i18n.t('validationRules.policyTypeRequired');
        },

        transactionDateRulesMessage(){
            return this.$i18n.t('validationRules.fieldRequired');
        },

        emailRulesMessage(){
            return this.$i18n.t('validationRules.emailValid');
        },

        nameRulesMessageNameRequired(){
            return this.$i18n.t('validationRules.nameRequired');
        },

        nameRulesMessageNameInvalid(){
            return this.$i18n.t('validationRules.nameInvalid');
        },

        mortgageAgentNameMessage(){
            return this.$i18n.t('validationRules.nameRequired');
        },
    },

    async created(){
        this.initialDialog = true;
      var currentUrl = window.location.pathname;
      var urlParams = currentUrl.split("/");
      let brand = null;
      let agencyKey = null;

      if(urlParams.length !== 3){
        this.initialDialog = false;
      } else {
        brand = urlParams[1];
        agencyKey = urlParams[2];

        try{
            let result = await axios.get(`/formInfos/${brand}/${agencyKey}`);

            if(result.status === 200){
                this.agency = result.data.agency;
                this.provinces = result.data.provinces;
                this.availabilitiesItems = result.data.availabilites;
                this.policiyTypes = result.data.policiesTypes;
                this.languages = result.data.languages;

                //default value section

                this.form.agencyId = this.agency.id;

                if(this.agency.agen_province){
                    this.form.province = this.agency.agen_province;
                }

                if(this.agency.agen_lang){
                    this.form.language = this.agency.agen_lang;
                }

                //on set la langue du form
                if(this.agency.agen_lang.toLowerCase() === "french"){
                    this.$root.$i18n.locale = "fr";
                }

                this.form.agencyType = this.agency.cf_1594;

                // if(this.agency.agen_lob){
                //     //par defaut, on selection habitation, cas ou on a 2 choix ou plus
                //     if(this.policiyTypes.length > 1){
                //         this.form.policyType.push("Habitation");
                //     } else { //sinon  met default select
                //         this.form.policyType.push(this.policiyTypes[0].value);
                //     }
                // }

            }
        } catch (err){
            if(err.response.status === 404){
                if(err.response.data.redirect){
                    window.location.href = err.response.data.redirect;
                }
            }
        }

        this.initialDialog = false;
      }

        await this.$recaptchaLoaded();
        const recaptcha = this.$recaptchaInstance;
        // show reCAPTCHA badge:
        recaptcha.showBadge();

    },


    data(){
      return {
        dialog: false,
        dialogSuccess: false,
          dialogError: false,
        initialDialog: false,
          searchMortgageBrokers: null,
          mortageBrokersListIsLoading: false,
        form: {
          firstName: "",
          lastName: "",
          streetAddress: "",
          city: "",
          province: "",
          postalCode: "",
          email: "",
          mobilePhone: "",
          availabilities: [],
          language: "",
          policyType: [],
          transactionDate: "",
            description : "",
          leadsource : "",
          agencyKey: "",
          agencyId: "",
          agencyProvince: "",
          agencyType: "",
          mortgageAgent: {
            id: "",
            name: "",
            email: "",
            phone: "",
            mbrkNo: "",
            mbrkId: ""
          },
          sendAutomaticEmail : true
        },
        formValid: false,
        mortgageAgentLockInfos: false,

        streetAddressRules: [
          v => !!v || this.streetAddressRulesMessage,
        ],

        cityRules: [
          v => !!v || this.cityRulesMessage
        ],

        provinceRules: [
          v => !!v || this.provinceRulesMessage
        ],

        postalCodeRules: [
          //v => !!v || 'Postal code is required'
        //   v => /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/.test(v) || 'Postal code be in a valid format',

        ],

        mobilePhoneRules: [
          v => !!v || this.mobilePhoneRulesMessage
        //   v => /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(v) || 'Phone must be valid format',
        ],

        availabilitiesRules: [
          v => v.length > 0 || this.availabilitiesRulesMessage
        ],

        languageRules: [
          v => !!v || this.languageRulesMessage
        ],

        policyTypeRules: [
          v => v.length > 0 || this.policyTypeRulesMessage
        ],

        transactionDateRules: [
          v => !!v || this.transactionDateRulesMessage
        ],

        emailRules: [
          // v => !!v || 'E-mail is required',
          v => /.+@.+/.test(v) || this.emailRulesMessage
        ],

        nameRules: [
          v => !!v || this.nameRulesMessageNameRequired,
          v => v.length >= 2 || this.nameRulesMessageNameInvalid
        ],

          mortgageAgentName: [
              v => !!v || this.mortgageAgentNameMessage
          ],

        agency: null,
        mortgageBrokers: [],
        selectedAgent: null,
        agentEmail: null,
        modalTransactionDate: null,
        transactionDate: null,
        provinces: [],
        availabilities: [],
        availabilitiesItems: [],
        languages : [],
        policiyTypes: [],
      }
    },

    methods: {
        policiesTypesHas(policy){
            return this.policiyTypes.find(elem => elem.value === policy);
        },
        addDay(date, days) {
            date.setDate(date.getDate() + days);
            return date;
        },

        disablePastDates(val) {
            let todayPlusTwoDays = this.addDay(new Date(), 2);
            return val >= todayPlusTwoDays.toISOString().substr(0, 10);
        },

        async validate () {
            let validate = this.$refs.form.validate();
            if(validate){
                try {
                    this.dialog = true;
                    this.form.leadsource = this.agency.agen_brand;
                    this.form.agencyKey = this.agency.agen_firm_key;
                    this.form.agencyProvince = this.agency.agen_province;

                    await this.$recaptchaLoaded();
                    const token = await this.$recaptcha('loginSecretAction');

                    let result = await axios.post("/createLead", {data: this.form, gRecaptchaResponse: token,}, );
                    await new Promise(r => setTimeout(r, 1500));
                    this.clearForm();
                    this.dialog = false;

                    if (result.status === 200) {
                        //this.clearForm();
                        this.dialogSuccess = true;
                    } else {
                        this.dialog = false;
                        this.dialogError = true;
                    }
                } catch (e) {
                    this.dialog = false;
                    this.dialogError = true;
                }
            }
        },

        reset () {
            this.$refs.form.reset()
        },

        resetValidation () {
            this.$refs.form.resetValidation()
        },

        checkSelectedAgent(){
            this.mortgageAgentLockInfos = false;
            if(this.selectedAgent){
                let agent = this.mortgageBrokers.find(elem => elem.id === this.selectedAgent);
                if(agent){
                    this.form.mortgageAgent.id = agent.id;
                    this.form.mortgageAgent.name = agent.mbrk_name;
                    this.form.mortgageAgent.email = agent.mbrk_email;
                    this.form.mortgageAgent.phone = agent.mbrk_phone;
                    this.form.mortgageAgent.mbrkId = agent.mbrk_id;
                    this.form.mortgageAgent.mbrkNo = agent.mbrk_no;
                    this.mortgageAgentLockInfos = true;
                } else {
                    this.mortgageAgentLockInfos = false;
                }
            }
        },

        clearForm(){
            this.form.firstName = "";
            this.form.lastName = "";
            this.form.streetAddress = "";
            this.form.city = "";
            this.form.province = "";
            this.form.postalCode = "";
            this.form.email = "";
            this.form.mobilePhone = "";
            this.form.availabilities = [];
            this.form.language = "";
            this.form.policyType = [];
            this.form.leadsource = "";
            this.form.transactionDate = "";
            this.form.description = "";
            this.form.agencyId = "";
            this.form.agencyType = "";
            this.form.sendAutomaticEmail = true;

            this.transactionDate = "";
            this.clearMortgageAgentInfos();
            this.resetValidation();
        },

        clearMortgageAgentInfos(){
            this.form.mortgageAgent.id = "";
            this.form.mortgageAgent.name = "";
            this.form.mortgageAgent.email = "";
            this.form.mortgageAgent.phone = "";
            this.selectedAgent = null;
            this.mortgageAgentLockInfos = false;
            this.mortgageBrokers = [];
        },

        uppercaseField(){
            this.form.postalCode = this.form.postalCode.toUpperCase();
        },

        async showMortgageBrokerPdf(){
            let data = {
                "agency" : this.agency,
                "mortgageBroker" : this.form.mortgageAgent,
                "language" : this.form.language
            }
            let res = await axios.post("/createMortageBrokerPfdLink", data);
            window.open(`/mortgageBroker/pdf/${res.data.linkToken}`, "_blank");
        },

        transactionDateValue(){
            if(this.transactionDate !== undefined || this.transactionDate !== null){
                let today = new Date();
                let plusDays = this.addDay(today,this.transactionDate);
                this.form.transactionDate = plusDays.toLocaleDateString("fr-CA");
            } else {
                this.form.transactionDate = "";
            }
        }


    },

    watch: {
        searchMortgageBrokers(val) {
            //console.log(val, oldVal);
            this.mortageBrokersListIsLoading = true
            if(val) {
                // Lazily load input items
                axios.get(`/mortgagesBrokers/${val}/agency/${this.agency.agen_firm_key}`).then(res => {
                    this.mortgageBrokers = res.data.mortgageBrokers;
                    //console.log(res);
                }).catch(err => {
                    console.log(err)
                }).finally(() => (this.mortageBrokersListIsLoading = false))
            } else {
                this.mortgageBrokers = [];
                this.mortageBrokersListIsLoading = false;
            }

        },
    }
}
</script>

<style scoped>
.vcard-title {
  padding: 5px;
}

.bg-white {
    background-color: white;
}

.uppercase {
    text-transform: uppercase;
}

.big-section{
    text-align: center;
}

.head-section {
    margin-top: 0px;
}

.section-infos{

}

.border-col {
    border: 2px solid black;
    border-radius: 5px;
    padding: 20px;

}

</style>
